<template>
  <div class="role-management-grid-container">
    <div class="grid-toolbar">
      <div class="grid-toolbar-title">
        <p>Role Management</p>
      </div>
      <div class="search-bar">
        <span class="k-textbox k-grid-search k-display-flex">
          <k-input
            :style="{ width: '430px' }"
            :value="searchWord"
            v-model="searchWord"
            :placeholder="'Search by Name, Email or Role'"
            :inputPrefix="'prefix'"
            @input="debouncedOnSearch"
          >
            <template v-slot:prefix>
              <span class="k-input-icon k-icon k-i-search"></span>
            </template>
          </k-input>
        </span>
      </div>
    </div>
    <Grid
      ref="grid-table"
      :style="{ height: '100%', minHeight: '300px' }"
      :data-items="gridData"
      :edit-field="'inEdit'"
      :sortable="true"
      :filterable="false"
      :sort="sort"
      :pageable="gridPageable"
      :take="take"
      :skip="skip"
      :total="total"
      class="role-management-grid"
      @itemchange="itemChange"
      @datastatechange="dataStateChange"
      :columns="columns"
      :loader="isLoading"
    >
      <template v-slot:action-cell="{ props }">
        <action-cell
          :dataItem="props.dataItem"
          :index="props.index"
          :id="props.dataItem.userId"
          @edit="edit"
          @save="save"
          @cancel="cancel"
        />
      </template>
      <template v-slot:role-dropdown="{ props }">
        <role-dropdown
          :dataItem="props.dataItem"
          :index="props.rowIndex"
          :dataItems="rolesList"
          :fieldValueKey="'roleId'"
          :fieldTextKey="'roleName'"
          @change="
            (event, value) => dropdownChange(event, value, props.dataItem)
          "
        />
      </template>
      <grid-norecords v-if="!isLoading"> No data available.</grid-norecords>
      <grid-norecords v-else> Loading....</grid-norecords>
    </Grid>
  </div>
</template>
<script>
import { Grid, GridToolbar, GridNoRecords } from '@progress/kendo-vue-grid'
import { Input } from '@progress/kendo-vue-inputs'
import { debounce } from 'lodash'
import { mapActions, mapState } from 'vuex'
import Snackbar from '@/components/Snackbar'
import { Button } from '@progress/kendo-vue-buttons'
import { process } from '@progress/kendo-data-query'
import SingleActionDropDown from '@/components/DMP/SingleActionDropDown.vue'
import GridCommandCell from '@/components/DMP/GridCommandCell.vue'

export default {
  components: {
    Grid: Grid,
    'k-input': Input,
    'grid-toolbar': GridToolbar,
    'grid-norecords': GridNoRecords,
    'action-cell': GridCommandCell,
    'role-dropdown': SingleActionDropDown,
    kbutton: Button
  },
  data() {
    return {
      filter: null,
      sort: [],
      pageable: true,
      gridData: [],
      skip: 0,
      take: 10,
      total: 0,
      updatedData: [],
      editID: null,
      searchWord: '',
      debounceTimer: 0, // Increase the timer, if debounce is needed
      columns: [
        {
          field: 'firstName',
          title: 'First Name',
          filterable: false,
          editable: false
        },
        {
          field: 'lastName',
          title: 'Last Name',
          editable: false,
          filterable: false
        },
        { field: 'email', title: 'Email', filterable: false, editable: false },
        {
          field: 'roleName',
          title: 'Role',
          cell: 'role-dropdown',
          filterable: false
        },
        {
          field: 'inEdit',
          title: 'Actions',
          cell: 'action-cell',
          filterable: false,
          sortable: false
        }
      ],
      gridData: [],
      gridPageable: {
        buttonCount: 5,
        info: true,
        type: 'numeric',
        pageSizes: [10, 20, 50, 100],
        previousNext: true
      }
    }
  },
  computed: {
    ...mapState('userManagement', [
      'activeUsersList',
      'rolesList',
      'isLoading'
    ]),
    hasItemsInEdit() {
      return this.gridData && this.gridData.filter((p) => p.inEdit).length > 0
    }
  },
  watch: {
    activeUsersList: {
      handler(newVal, oldVal) {
        if (newVal) {
          this.gridData = this.processGridData(newVal)
        }
      },
      immediate: true
    }
  },
  async mounted() {
    try {
      await this.fetchActiveUsers()
      await this.fetchRoles()
    } catch (error) {
      Snackbar({
        message: 'Failed to fetch data, please refresh and try again!',
        type: 'is-danger'
      })
    }
  },
  created() {
    // Create a debounced version of onSearch with a delay of 300ms
    this.debouncedOnSearch = debounce(this.onSearch, this.debounceTimer)
  },
  methods: {
    ...mapActions('userManagement', [
      'fetchActiveUsers',
      'fetchRoles',
      'updateUserDetails'
    ]),
    onSearch(e) {
      let inputValue = e.target.value

      if (inputValue && this.activeUsersList) {
        this.filter = this.createSearchFilter()
        this.skip = 0
      } else {
        this.filter = null
      }
      this.gridData = this.processGridData(this.activeUsersList)
    },
    debouncedOnSearch(e) {
      this.debouncedOnSearch(e)
    },
    itemChange(e) {
      if (e.dataItem.userId) {
        let index = this.gridData.findIndex(
          (p) => p.userId === e.dataItem.userId
        )
        let updated = Object.assign({}, this.gridData[index], {
          [e.field]: e.value
        })
        this.gridData.splice(index, 1, updated)
      } else {
        e.dataItem[e.field] = e.value
      }
    },
    edit(e) {
      let index = this.gridData.findIndex((p) => p.userId === e.dataItem.userId)
      let updated = Object.assign({}, this.gridData[index], {
        inEdit: true,
        oldRoleId: e.dataItem.roleId, // store the original roleId
        oldRoleName: e.dataItem.roleName // store the original roleName
      })
      this.gridData.splice(index, 1, updated)
    },
    async save(e) {
      let index = this.gridData.findIndex((p) => p.userId === e.dataItem.userId)
      let item = this.gridData[index]

      // Retrieve oldRoleId stored in the edit phase
      const { oldRoleId, oldRoleName, roleId } = item

      // Store the current page state and filter state
      const currentSkip = this.skip
      const currentFilter = this.filter

      // Only proceed if there's a change in the role
      if (oldRoleId != roleId) {
        const payload = {
          userId: item.userId,
          roleId: roleId
        }

        const isUpdateSuccess = await this.updateUserDetails(payload)
        if (isUpdateSuccess) {
          Snackbar({
            message: 'Update successful!',
            type: 'is-success'
          })

          // Remove inEdit flag and save the change permanently
          this.gridData[index].inEdit = false

          // Fetch active users and retain the current page and filter state
          await this.fetchActiveUsers()
          this.skip = currentSkip
          this.filter = currentFilter
          this.gridData = this.processGridData(this.activeUsersList)
        } else {
          // Revert to old role on failure
          this.gridData[index].roleId = oldRoleId
          this.gridData[index].roleName = item.oldRoleName // Revert the name as well
          Snackbar({
            message: 'Update failed, please try again!',
            type: 'is-danger'
          })
        }
      } else {
        Snackbar({
          message: 'Cannot update with the same role!',
          type: 'is-danger'
        })
      }
    },
    update(data, item, remove) {
      let updated
      let index = data.findIndex((p) => item.userId && p.userId === item.userId)
      // Need to look for edge cases where the index is not found
      if (index >= 0) {
        updated = Object.assign({}, item)
        data[index] = updated
      }
      if (index >= 0 && remove) {
        data = data.splice(index, 1)
      }

      return { item: data[index], index }
    },
    cancel(e) {
      if (e.dataItem.userId) {
        let index = this.gridData.findIndex(
          (p) => p.userId === e.dataItem.userId
        )

        if (index >= 0) {
          // need to revert to the original value of roleId and role name, as well as delete the oldRoleId and oldRoleName if it exists
          let updated = Object.assign({}, this.gridData[index], {
            inEdit: undefined,
            roleId: e.dataItem.oldRoleId,
            roleName: e.dataItem.oldRoleName
          })
          // Delete the old role id and role name
          delete updated.oldRoleId
          delete updated.oldRoleName
          this.gridData.splice(index, 1, updated)
        }
      } else {
        // We need to update all the inEdit flag to false
        this.gridData = this.gridData.map((p) => {
          return { ...p, inEdit: undefined }
        })
      }
    },
    processGridData(data) {
      const result = process(data, {
        take: this.take,
        skip: this.skip,
        sort: this.sort,
        filter: this.filter
      })

      // Set the total to reflect filtered and paginated results
      this.total = result.total
      return result.data
    },
    getData() {
      this.gridData = this.processGridData(this.activeUsersList)
    },
    createAppState(dataState) {
      this.take = dataState.take
      this.skip = dataState.skip
      this.filter = this.filter
      this.sort = dataState.sort
      this.getData()
    },
    dataStateChange(event) {
      // Retain the current filter and skip state
      this.skip = event.data.skip
      this.sort = event.data.sort
      this.take = event.data.take

      if (this.searchWord) {
        this.filter = this.createSearchFilter()
      } else {
        this.filter = event.data.filter || this.filter
      }
      this.createAppState(event.data)
    },
    createSearchFilter() {
      if (this.searchWord) {
        const searchFilter = {
          logic: 'or',
          filters: [
            {
              field: 'firstName',
              operator: 'contains',
              value: this.searchWord
            },
            {
              field: 'lastName',
              operator: 'contains',
              value: this.searchWord
            },
            {
              field: 'email',
              operator: 'contains',
              value: this.searchWord
            },
            {
              field: 'roleName',
              operator: 'contains',
              value: this.searchWord
            }
          ]
        }
        return searchFilter
      }
      return null
    },
    dropdownChange(e, selectedRole, dataItem) {
      const { userId } = dataItem

      const slicedData = this.gridData.slice()

      const index = slicedData.findIndex((p) => p.userId === userId)

      if (index >= 0) {
        // Temporarily store the new role selection
        slicedData[index].roleId = selectedRole.value
        slicedData[index].roleName = selectedRole.text

        // Update the grid data to reflect the change in the dropdown
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .grid-toolbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-bottom: 26px;
  .grid-toolbar-title {
    p {
      text-align: left;
      font-weight: 600;
      font-style: normal;
      font-size: 28px;
      line-height: 15px;
      font-family: Quicksand;
      letter-spacing: 0px;
      color: #00218a;
      opacity: 1;
      line-height: 1.5rem;
    }
  }
}
.k-toolbar {
  .grid-toolbar-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    .toolbar-row {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
    }

    .row-reverse {
      flex-direction: row-reverse;
    }
  }
}

.role-management-grid-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  ::v-deep .role-management-grid {
    .k-grid-header {
      width: 100%;
      padding-right: 0px !important;
      .k-table-row {
        .k-table-th {
          background: #dfdfdf;
          font-size: 16px;
          color: #000000;
          line-height: 19px;
        }
      }
      .k-header > div > a {
        top: 4px;
      }
      .k-header.active > div > a {
        color: #fff;
        background-color: #ff6358;
        top: 4px;
      }
    }

    .k-grid-aria-root {
      .k-grid-container {
        .k-grid-content {
          overflow: auto;
          .k-grid-table-wrap {
            .k-table {
              .k-table-tbody {
                .k-table-alt-row {
                  background-color: none;
                }
                .k-selected {
                  background-color: none;
                }
                .k-table-row {
                  border: 1px solid black;
                  .k-table-td {
                    font-style: normal;
                    font-variant: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 20px;
                    font-family: Quicksand;

                    letter-spacing: 0.16px;
                    color: #000000;
                  }
                }
              }
            }
          }
        }
      }
    }

    .k-grid-pager {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      .k-pager-info {
        display: flex;
        text-align: right;
        justify-content: flex-start;
      }
    }
  }

  ::v-deep .grid-toolbar {
    .search-bar {
      .k-grid-search {
        height: 32px;
        width: 100%;
        .k-input {
          border: 1px solid #cccccc;

          input {
            // height: 44px;
            font-size: 13px;
            line-height: 20px;
            font-weight: 600;
            font-family: Quicksand;
            font-style: normal;
          }

          .k-input-inner {
            margin-left: 0.1rem;
          }

          .k-input-prefix {
            margin-left: 0.5rem;
            color: #cccccc;
          }
        }
      }
    }
  }
}

.filter-indicator {
  .k-grid-column-menu {
    background-color: #ff6358;
  }
}

@media (min-width: 1700px) {
  .role-management-grid-container {
    .role-management-grid {
      .k-grid-header {
        .k-table-row {
          .k-table-th {
            font-size: 18px;
            line-height: 23px;
          }
        }
      }

      .k-grid-aria-root {
        .k-grid-container {
          .k-grid-content {
            overflow: auto;
            width: 100%;
            .k-grid-table-wrap {
              .k-table {
                .k-table-tbody {
                  .k-table-row {
                    .k-table-td {
                      font-size: 16px;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .k-grid-pager {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        .k-pager-info {
          font-size: 16px;
        }
        .k-pager-sizes {
          font-size: 16px;
          .k-dropdownlist {
            font-size: 16px;
          }
        }
        .k-pager-numbers-wrap {
          font-size: 16px;
          .k-pager-numbers {
            button {
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}
</style>
