<template>
  <div class="data-grid-container">
    <div class="grid-toolbar">
      <div class="grid-toolbar-title">
        <h1 class="titleText">Team Management</h1>
      </div>
      <div class="search-bar">
        <span class="k-textbox k-grid-search k-display-flex">
          <k-input
            :style="{ width: '430px' }"
            :value="searchWord"
            v-model="searchWord"
            :placeholder="'Search by Team Name'"
            :inputPrefix="'prefix'"
            @input="debouncedOnSearch"
          >
            <template v-slot:prefix>
              <span class="k-input-icon k-icon k-i-search"></span>
            </template>
          </k-input>
        </span>
      </div>
    </div>
    <Grid
      ref="grid-table"
      :style="{ height: '100%', minHeight: '300px' }"
      :data-items="gridData"
      :edit-field="'inEdit'"
      :sortable="{ allowUnsort: true }"
      :sort="sort"
      :pageable="gridPageable"
      :take="take"
      :skip="skip"
      :total="total"
      class="grid-table"
      @itemchange="itemChange"
      @datastatechange="dataStateChange"
      :columns="columns"
      :loader="isLoading"
    >
      <template v-slot:action-cell="{ props }">
        <custom
          :data-item="props.dataItem"
          :index="props.index"
          @edit="edit"
          @save="save"
          @cancel="cancel"
        />
      </template>

      <grid-toolbar>
        <kbutton title="Add New" @click="insert" class="k-grid-add">
          Add New Team
        </kbutton>
      </grid-toolbar>

      <grid-norecords v-if="!isLoading"> No data available.</grid-norecords>
      <grid-norecords v-else>
        Please wait while the record is being fetched.</grid-norecords
      >
    </Grid>
  </div>
</template>
<script>
import { Grid, GridToolbar, GridNoRecords } from '@progress/kendo-vue-grid'
import { Input } from '@progress/kendo-vue-inputs'
import { debounce } from 'lodash'
import { mapActions, mapState } from 'vuex'
import Snackbar from '@/components/Snackbar'
import { Button } from '@progress/kendo-vue-buttons'
import { process } from '@progress/kendo-data-query'
import CommandCell from '@/views/DataManagement/UserManagement/TeamManagement/CommandCell.vue'
export default {
  components: {
    Grid: Grid,
    'k-input': Input,
    'grid-toolbar': GridToolbar,
    'grid-norecords': GridNoRecords,
    kbutton: Button,
    custom: CommandCell
  },
  data: function () {
    return {
      filter: null,
      sort: [],
      pageable: true,
      gridData: [],
      skip: 0,
      take: 10,
      total: 0,
      updatedData: [],
      editID: null,
      searchWord: '',
      debounceTimer: 0, // Increase the timer if debound is needed
      columns: [
        {
          field: 'name',
          title: 'Team Name',
          editable: true,
          sortable: true
        },
        {
          field: 'enrichmentName',
          title: 'Work Type',
          editable: false,
          sortable: false
        },
        {
          field: 'inEdit',
          title: 'Actions',
          cell: 'action-cell',
          filterable: false,
          sortable: false
        }
      ],
      gridData: [],
      gridPageable: {
        buttonCount: 5,
        info: true,
        type: 'numeric',
        pageSizes: [10, 20, 50, 100],
        previousNext: true
      }
    }
  },
  computed: {
    ...mapState('user', ['userDetails']),
    ...mapState('teamManagement', ['teamList', 'isLoading'])
  },
  watch: {
    teamList: {
      handler: function (newVal) {
        if (newVal) {
          this.gridData = this.processGridData(newVal)
        }
      },
      immediate: true
    }
  },
  async mounted() {
    try {
      await this.getTeamData(this.userDetails.userId)
    } catch (error) {
      Snackbar({
        message: 'Failed to fetch data, please refresh and try again!',
        type: 'is-danger'
      })
    }
  },
  created() {
    // Create a debounced version of onSearch with a delay of 300ms
    this.debouncedOnSearch = debounce(this.onSearch, this.debounceTimer)
  },
  methods: {
    ...mapActions('teamManagement', ['getTeamData', 'updateTeamData']),
    onSearch(e) {
      let inputValue = e.target.value
      if (inputValue && this.teamList) {
        this.filter = this.createSearchFilter()
      } else {
        this.filter = null
      }
      // reset pagination to page 1
      this.skip = 0
      this.gridData = this.processGridData(this.teamList)
    },
    debouncedOnSearch(e) {
      this.debouncedOnSearch(e)
    },

    itemChange(e) {
      if (e.dataItem.team_id) {
        let index = this.gridData.findIndex(
          (p) => p.team_id === e.dataItem.team_id
        )
        let updated = Object.assign({}, this.gridData[index], {
          [e.field]: e.value
        })
        this.gridData.splice(index, 1, updated)
      } else {
        e.dataItem[e.field] = e.value
      }
    },
    insert() {
      const dataItem = { inEdit: true }
      this.gridData.splice(0, 0, dataItem)
    },

    edit: function (e) {
      let index = this.gridData.findIndex(
        (p) => p.team_id === e.dataItem.team_id
      )

      if (index >= 0) {
        // Store the original item data before editing
        let originalItem = Object.assign({}, this.gridData[index])

        let updated = Object.assign({}, this.gridData[index], {
          inEdit: true,
          originalData: originalItem
        })

        this.gridData.splice(index, 1, updated)
      }
    },

    cancel: function (e) {
      let index = this.gridData.findIndex(
        (p) => p.team_id === e.dataItem.team_id
      )

      if (index >= 0) {
        // use originalData if cancle button clicked.
        let originalItem = Object.assign(
          {},
          this.gridData[index].originalData,
          {
            inEdit: undefined,
            originalData: undefined
          }
        )

        this.gridData.splice(index, 1, originalItem)
      }

      // Handle the case if the item was newly created and should be removed entirely
      if (e.dataItem.team_id === undefined) {
        let newItemIndex = this.gridData.findIndex(
          (p) => JSON.stringify(e.dataItem) === JSON.stringify(p)
        )

        if (newItemIndex >= 0) {
          this.gridData.splice(newItemIndex, 1) // Remove the new item if canceled
        } else this.gridData.shift()
      }
    },

    save: async function (e) {
      let index = this.gridData.findIndex(
        (p) => p.team_id === e.dataItem.team_id
      )

      if (index >= 0) {
        let updatedItem = Object.assign({}, this.gridData[index], {
          name: e.dataItem.name,
          inEdit: false,
          originalData: undefined
        })

        const data = {
          payload: {
            teamId: e.dataItem.team_id,
            name: e.dataItem.name
          },
          userId: this.userDetails.userId
        }

        try {
          const response = await this.updateTeamData(data)
          if (response.status === 200) {
            this.gridData.splice(index, 1, updatedItem)

            Snackbar({
              message: response?.info || 'Team Update successfully.',
              type: 'is-success'
            })
            await this.getTeamData(this.userDetails.userId)
          } else {
            Snackbar({
              message: response?.info,
              type: 'is-danger'
            })
          }
        } catch (error) {
          Snackbar({
            message: 'Error in Team Updated.',
            type: 'is-danger'
          })
        }
      }
    },

    processGridData(data) {
      const result = process(data, {
        take: this.take,
        skip: this.skip,
        sort: this.sort,
        filter: this.filter
      })
      // Set the total to reflect filtered and paginated results
      this.total = result.total
      return result.data
    },
    getData: function () {
      this.gridData = this.processGridData(this.teamList)
    },
    createAppState: function (dataState) {
      this.take = dataState.take
      this.skip = dataState.skip
      this.filter = this.filter
      this.sort = dataState.sort
      this.getData()
    },
    dataStateChange: function (event) {
      // Retain the current filter and skip state
      this.skip = event.data.skip
      this.sort = event.data.sort
      this.take = event.data.take

      if (this.searchWord) {
        this.filter = this.createSearchFilter()
      } else {
        this.filter = event.data.filter || this.filter
      }
      this.createAppState(event.data)
    },
    createSearchFilter() {
      if (this.searchWord) {
        const searchFilter = {
          logic: 'or',
          filters: [
            {
              field: 'name',
              operator: 'contains',
              value: this.searchWord
            }
          ]
        }
        return searchFilter
      }
      return null
    }
  }
}
</script>

<style lang="scss" scoped>
.titleText {
  text-align: left;
  font-weight: 600;
  font-style: normal;
  font-size: 28px;
  line-height: 15px;
  font-family: Quicksand;
  letter-spacing: 0px;
  color: #00218a;
  opacity: 1;
  line-height: 1.5rem;
}
.k-grid-add {
  background-color: #535eeb !important;
  color: #fff !important;
  border: 1px solid #535eeb !important;
}

.k-grid-add:hover {
  background-color: #00218a;
  border: 1px solid #00218a;
  color: #fff;
}
.grid-toolbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.k-toolbar {
  .grid-toolbar-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    .toolbar-row {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      // flex-direction: row-reverse;
    }

    .row-reverse {
      flex-direction: row-reverse;
    }
  }
}

.data-grid-container {
  padding-left: 30px;
  padding-right: 30px;

  .grid-table {
    ::v-deep .k-grid-header {
      padding: 0px;
      .k-table-row {
        .k-table-th {
          background: #dfdfdf;
          font-size: 16px;
          color: #000000;
          line-height: 19px;
        }
      }
      .k-header > div > a {
        top: 4px;
      }
      .k-header.active > div > a {
        color: #fff;
        background-color: #ff6358;
        top: 4px;
      }
    }

    .k-grid-aria-root {
      .k-grid-container {
        .k-grid-content {
          .k-grid-table-wrap {
            .k-table {
              .k-table-tbody {
                .k-table-alt-row {
                  background-color: none;
                }
                .k-selected {
                  background-color: none;
                }
                .k-table-row {
                  border: 1px solid black;
                  .k-table-td {
                    font-style: normal;
                    font-variant: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 20px;
                    font-family: Quicksand;

                    letter-spacing: 0.16px;
                    color: #000000;
                  }
                }
              }
            }
          }
        }
      }
    }

    .k-grid-pager {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      .k-pager-info {
        display: flex;
        text-align: right;
        justify-content: flex-start;
      }
    }
  }

  .grid-toolbar {
    margin-bottom: 15px;
    .search-bar {
      .k-grid-search {
        height: 32px;
        width: 100%;
        .k-input {
          border: 1px solid #cccccc;

          input {
            font-size: 13px;
            line-height: 20px;
            font-weight: 600;
            font-family: Quicksand;
            font-style: normal;
          }

          .k-input-inner {
            margin-left: 0.1rem;
          }

          .k-input-prefix {
            margin-left: 0.5rem;
            color: #cccccc;
          }
        }
      }
    }
  }
}

.k-animation-container {
  .k-list-container {
    .k-list {
      .k-list-content {
        .k-list-ul {
          .k-list-item.k-selected {
            background-color: #00218a;
          }
        }
      }
    }
  }
}

.filter-indicator {
  .k-grid-column-menu {
    background-color: #ff6358;
  }
}

@media (min-width: 1700px) {
  .data-grid-container {
    .grid-table {
      .k-grid-header {
        .k-table-row {
          .k-table-th {
            font-size: 18px;
            line-height: 23px;
          }
        }
      }

      .k-grid-aria-root {
        .k-grid-container {
          .k-grid-content {
            .k-grid-table-wrap {
              .k-table {
                .k-table-tbody {
                  .k-table-row {
                    .k-table-td {
                      font-size: 16px;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .k-grid-pager {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        .k-pager-info {
          font-size: 16px;
        }
        .k-pager-sizes {
          font-size: 16px;
          .k-dropdownlist {
            font-size: 16px;
          }
        }
        .k-pager-numbers-wrap {
          font-size: 16px;
          .k-pager-numbers {
            button {
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}
</style>
