<template>
  <div class="user-management-container">
    <!-- user management tabs -->
    <div class="form-container">
      <div class="tab-container">
        <TabStrip :selected="selected" @select="onSelect" :tabs="tabs">
          <template v-slot:TabOne>
            <RoleManagement v-if="selected === 0"></RoleManagement>
          </template>
          <template v-slot:TabTwo>
            <TeamManagement v-if="selected === 1"> </TeamManagement>
          </template>
          <template v-slot:TabThree>
            <UserTeamManagement v-if="selected === 2"></UserTeamManagement>
          </template>
        </TabStrip>
      </div>
    </div>
  </div>
</template>

<script>
import { TabStrip } from '@progress/kendo-vue-layout'
import UserTeamManagement from './UserTeamManagement/UserTeamManagement.vue'
import TeamManagement from './TeamManagement/TeamManagement.vue'
import RoleManagement from './RoleManagement/RoleManagement.vue'

export default {
  name: 'DMPUserManagement',
  components: {
    TabStrip,
    UserTeamManagement,
    TeamManagement,
    RoleManagement
  },
  data() {
    return {
      selected: 0,
      tabs: [
        {
          title: 'Role Management',
          content: 'TabOne'
        },
        {
          title: 'Team Management',
          content: 'TabTwo'
        },
        {
          title: 'User Team Management',
          content: 'TabThree'
        }
      ]
    }
  },
  computed: {},

  mounted() {},

  methods: {
    onSelect(e) {
      this.selected = e.selected
    }
  }
}
</script>
<style lang="scss" scoped>
.user-management-container {
  max-width: 1450px;
  margin: 0 auto;
  margin-top: 50px;
  background-color: var(--background-color);

  .entities-header {
    display: flex;
    align-items: center;

    .entities-add-btn {
      margin-left: auto;
    }
  }
}
</style>
<style lang="scss">
.user-management-container {
  max-width: 1450px;
  margin: 0 auto;
  background-color: var(--background-color);
}

.user-management-container {
  .k-tabstrip {
    font-size: 16px;
    color: #000000;
    background-color: var(--dmp-entities-tab-menu-bg-color);

    .k-tabstrip-items {
      padding: 12px;
      width: 65%;
    }

    .k-tabstrip-items-wrapper .k-item {
      color: #7d7d7d;
    }

    .k-tabstrip-items-wrapper .k-item:active,
    .k-tabstrip-items-wrapper .k-item.k-active,
    .k-tabstrip-items-wrapper .k-item.k-selected {
      border-color: rgba(0, 0, 0, 0.08);
      border-bottom-color: rgba(0, 0, 0, 0.08);
      color: #000000;
      background-color: #dfdfdf;
    }
  }
}

.user-management-container {
  .mandatory-popup {
    .k-dialog-wrapper {
      .k-window-content {
        .popup-msg {
          text-align: left;
        }
      }
    }
  }
}
</style>
