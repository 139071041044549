<template>
  <td v-if="!dataItem.inEdit" :class="className">
    {{ dataItem[fieldTextKey] }}
  </td>
  <td class="custom-dropdown-cell" v-else>
    <dropdownlist
      @change="change"
      :value="selectedValue"
      :data-items="dataItems"
      :text-field="'text'"
      :value-field="'value'"
      :popup-settings="{ className: 'custom-dropdown-popup' }"
    ></dropdownlist>
  </td>
</template>
<script>
import { DropDownList } from '@progress/kendo-vue-dropdowns'

export default {
  components: {
    dropdownlist: DropDownList
  },
  props: {
    fieldValueKey: String,
    fieldTextKey: String,
    dataItem: Object,
    className: String,
    dataItems: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      selectedValue: this.dataItem[this.fieldValueKey]
    }
  },
  watch: {
    dataItem: {
      handler(newVal) {
        this.selectedValue = this.dataItems.find(
          (item) => item.value == newVal[this.fieldValueKey]
        )
      },
      immediate: true
    }
  },
  methods: {
    change(e) {
      const selectedItem = e.target.value
      this.selectedValue = selectedItem
      this.$emit('change', e, selectedItem)
    }
  }
}
</script>

<style lang="scss" scoped>
.custom-dropdown-cell {
  .k-dropdownlist {
    width: 100%;
  }
}
</style>
