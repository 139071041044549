<template>
  <td class="k-command-cell k-table-td">
    <kbutton
      :class="editButtonClass"
      :type="'button'"
      :theme-color="'primary'"
      @click="editHandler"
    >
      {{ editText }}
    </kbutton>
    <kbutton
      v-if="dataItem?.inEdit"
      :class="cancelButtonClass"
      @click="cancelHandler"
    >
      {{ cancelText }}
    </kbutton>
  </td>
</template>

<script>
import { Button } from '@progress/kendo-vue-buttons'

export default {
  components: {
    kbutton: Button
  },
  props: {
    dataItem: Object,
    index: Number
  },
  data() {
    return {
      navAttrs: {}
    }
  },
  computed: {
    editText() {
      if (this.dataItem['inEdit']) {
        return this.dataItem.team_id ? 'Update' : 'Add'
      } else {
        return 'Edit'
      }
    },
    cancelText() {
      if (this.dataItem['inEdit']) {
        return this.dataItem.team_id ? 'Cancel' : 'Discard'
      } else {
        return 'Remove'
      }
    },
    editButtonClass() {
      return this.dataItem['inEdit']
        ? 'k-grid-save-command'
        : 'k-grid-edit-command'
    },
    cancelButtonClass() {
      return this.dataItem['inEdit']
        ? 'k-grid-cancel-command'
        : 'k-grid-remove-command'
    }
  },
  methods: {
    editHandler: function () {
      const command = this.dataItem['inEdit'] ? 'save' : 'edit'
      this.$emit(command, { dataItem: this.dataItem, index: this.index })
    },
    cancelHandler: function () {
      const command = this.dataItem['inEdit'] ? 'cancel' : 'remove'
      if (command === 'cancel') {
        this.$emit(command, { dataItem: this.dataItem, index: this.index })
      } else if (!this.dataItem.team_id) {
        this.$emit('remove', { dataItem: this.dataItem, index: this.index })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.k-command-cell {
  .k-button {
    height: 25px;
    font-style: normal;
    font-variant: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    font-family: 'Quicksand', sans-serif;
    border-radius: 4px;
  }

  .k-grid-save-command,
  .k-grid-edit-command {
    background-color: #535eeb;
    color: #fff;
    border: 1px solid #535eeb;
    &:hover {
      background-color: #00218a;
      border: 1px solid #00218a;
      color: #fff;
    }
  }

  .k-grid-cancel-command,
  .k-grid-remove-command {
    background-color: #cccccc;
    color: #444444;
    border: 1px solid #cccccc;
    &:hover {
      background-color: #00218a;
      border: 1px solid #00218a;
      color: #fff;
      opacity: 0.8;
    }
  }
}
</style>
