import { render, staticRenderFns } from "./DMPUserManagement.vue?vue&type=template&id=63e16eb0&scoped=true&"
import script from "./DMPUserManagement.vue?vue&type=script&lang=js&"
export * from "./DMPUserManagement.vue?vue&type=script&lang=js&"
import style0 from "./DMPUserManagement.vue?vue&type=style&index=0&id=63e16eb0&prod&lang=scss&scoped=true&"
import style1 from "./DMPUserManagement.vue?vue&type=style&index=1&id=63e16eb0&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63e16eb0",
  null
  
)

export default component.exports